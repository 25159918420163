import styled from "styled-components";

import authBg from "../images/background.jpg";

export const AuthContainer = styled.div`
  display: flex;
  height: 100%;

  .logo {
    margin-bottom: 30px;

    img {
      height: 50px;
    }
  }

  .content {
    flex: 1;
    padding: 50px;
    max-width: 500px;
  }

  .background {
    flex: 1;
    background: url(${authBg});
    background-size: cover;
    background-position: top right;
  }

  h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 35px;
  }
`

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #CCC;

  h2 {
    font-size: 24px;
    line-height: 50px;
    margin-bottom: 0px;
    text-transform: capitalize;
  }
`

export const Form = styled.form`
  
`

export const IframeContainer = styled.div`
  width: inherit;

  iframe {
    width: inherit;
    height: 100%;
  }
`

export const Dropdown = styled.div`
  position: relative;
  display: inline-block;

  [data-attribute="dropbtn"] {
    background-color: #EA5527;
    color: white;
    padding: 5px 10px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    border-radius: 5px;

    &:hover, &:focus {
      background-color: #c1411a;
    }
  }

  [data-attribute="dropdown-content"] {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 80px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;

    a {
      color: black;
      padding: 5px 7px;
      text-decoration: none;
      display: block;
      font-size: 14px;

      &:hover {
        background-color: #ddd;
        cursor: pointer;
      }
    }
  }

  &:hover {
    [data-attribute="dropdown-content"] {
      display: block;
    }
  }
`