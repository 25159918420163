import React, { useRef } from "react";

import { useMutation } from "@apollo/client";

import { mutation, idToHash } from "utils";

import Nav from "./Nav";

import steps from "./Steps";

import styled from "styled-components";

import { UPDATE_PAGE } from "graphql/page";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Designer = (props) => {
  const { data, step, history } = props;
  const [updatePage] = useMutation(UPDATE_PAGE);

  const Step = steps[step || "design"];

  const pageId = idToHash(data.id);

  const childRef = useRef(null);

  const onSave = (nextStep=null) => () => {
    childRef.current.save(nextStep);
  };

  const savePage = async ({updates, nextStep, pagePublished=false}) => {
    mutation({ 
      name: "updatePage",
      func: updatePage,
      args: {
        id: data.id,
        ...updates,
      },
      callbackSuccess: () => {
        if(pagePublished) {
          history.push("/pages");
        } else if(nextStep !== null) {
          history.push(`/pages/${pageId}/${nextStep}`);
        }
      },
      callbackError: (error) => {
        alert(error)
      }
    });
  };

  const childrenProps = {
    ...props,
  }

  return (
    <Container>
      <Nav {...childrenProps} pageId={pageId} onSave={onSave} />
      <Step {...childrenProps} pageId={pageId} handleSave={savePage} ref={childRef} />
    </Container>
  );
};

export default Designer;
