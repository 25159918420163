import React, { useState } from 'react';

import { useFormik } from "formik";

import { object, string } from "yup";

import { Form } from "styles";

import { useLazyQuery } from "@apollo/client"

import { CHECK_SLUG_UNIQUENESS } from "graphql/site";

import { yupValidations, generateSlug } from "utils";

import mainImg from "images/home.png";

import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .main-image {
    img {
      height: 300px;
    }
  }

  h1 {
    font-size: 78px;
    font-weight: 600;
    letter-spacing: -2px;
    line-height: 1;
    margin-bottom: 20px;
  }

  p {
    font-size: 24px;
    line-height: 1.4;
  }

  form {
    margin: 40px auto 0px;
    max-width: 600px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      flex: 1;
      background-color: #ff5454;
      padding: 10px 20px;
      color: #FFF;
      border: 1px solid #812c2b;
      border-radius: 4px;
      cursor: pointer;
      font-weight: 600;
      max-width: 210px;
      margin-left: 10px;

      &:hover {
        background-color: #812c2b;
      }
    }
  }

  .form-field-container {
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
    border-radius: 4px;
    background-color: #000;
    overflow: hidden;
    margin-right: 10px;

    .form-error {
      position: absolute;
      top: 1px;
      right: 1px;
      bottom: 1px;
      line-height: 34px;
      font-size: 14px;
      font-weight: 600;
      background-color: #FFF;
      padding: 5px 10px;
      color: #FF0000;
    }

    .label {
      flex: 1;
      display: block;
      min-width: 115px;
      max-width: 115px;
      text-align: right;
      padding-right: 5px;
      font-size: 19px;
      color: #FFF;
      font-weight: 600;
    }

    .form-field {
      flex: 1;
      padding-left: 50px;
      padding: 10px 15px;
      font-size: 16px;
      min-width: 250px;
      border: 0px;
      border-left: 1px solid #CCC;
    }
  }
`

const CreatePage = ({ history, data, setData }) => {

  const validationSchema = object().shape({
    slug: string().required("Required")
      .test(
        "is-unique",
        "Not Available",
        async function (value) {
          await checkSlugUniqueness();
          if (value) {
            if (uniqueSlugData && !uniqueSlugData.isSiteSlugNameAvailable) return false;
          }
          return true;
        },
      ),
  });

  const formik = useFormik({
    initialValues: {
      slug: ""
    },
    validationSchema,
    onSubmit: (values) => {
      history.push("/create");
    },
  });

  const [
    checkSlugUniqueness,
    { data: uniqueSlugData },
  ] = useLazyQuery(CHECK_SLUG_UNIQUENESS, {
    variables: { slugName: data.slug }
  });

  const handleClick = () => {
    history.push("/login");
  }

  return (
    <Container>
      <div className="main-image">
        <img src={mainImg} />
      </div>

      <div>
        <h1>Your page on the web</h1>
        <p>Simple, free, fully responsive one-page sites for anything.</p>

        <Form onSubmit={formik.handleSubmit}>
          <div className="form-field-container">
            <div className="label">my.page{' '}/</div>
            <input
              name="slug"
              placeholder="username"
              className="form-field"
              onChange={event => {
                let { value } = event.target;
                formik.setFieldValue("slug", value);
                value = generateSlug(value);
                setData({ ...data, slug: value });
              }}
              onBlur={formik.handleBlur}
              value={formik.values.slug}
            />

            {formik.errors.slug && <span className="form-error">{formik.errors.slug}</span>}
            {formik.errors.error && <span className="form-error">{formik.errors.error}</span>}
          </div>
          <button type="submit" disabled={formik.isSubmitting || !formik.isValid}>
            Claim your Username
          </button>
        </Form>
        {/* <div dataattribute="cross-link">Already have an account? <a onClick={handleClick}>Log in</a></div> */}
      </div>
    </Container>
  );
}

export default CreatePage;