import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";

import Sites from "./Sites";
import Site from "./Site";
import NewSite from "./NewSite";

import { Container } from "reactstrap";

const SiteContainer = props => {
  let { path, url } = useRouteMatch();

  return (
    <Container>
      <Switch>
        <Route exact path={`${path}/new`}>
          <NewSite {...props} />
        </Route>
        <Route exact path={`${path}/:siteId`}>
          <Site {...props} />
        </Route>
        <Route exact path={path}>
          <Sites {...props} />
        </Route>
      </Switch>
    </Container>
  );
};

export default SiteContainer;
