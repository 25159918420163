import React from "react"

import { Redirect } from "react-router-dom";

const Dashboard = () => {
  return (
    <div>
      <Redirect
        to={{
          pathname: "/sites"
        }}
      />
    </div>
  );
};

export default Dashboard
