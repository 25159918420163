import React from "react";

import { Link, Redirect } from "react-router-dom";

import { useQuery, useMutation } from "@apollo/client";

import { PAGES_QUERY, DELETE_PAGE } from "graphql/page";

import { idToHash, mutation } from "utils";

import Loader from "../common/Loader";

import styled from "styled-components";

import { Button } from "reactstrap";
import { TopBar, Dropdown } from "styles";

const Container = styled.div`
  padding: 40px 20px;
`

const PagesList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 270px));
  grid-gap: 45px;
`

const Page = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid;
  margin-bottom: 20px;
  width: fit-content;
  padding: 10px 10px 0 10px;

  [data-attribute="page-image"] {
    position: relative;
    border: 1px solid;
    height: 250px;
    width: 250px;

    img {
      width: 100%;
    }

    [data-attribute="image-overlay"] {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #000000b3;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: .3s ease;
    }

    [data-attribute="image-overlay-btn"] {
      position: absolute;
      bottom: 0px;
      right: 0px;
      margin: 10px;
    }

    &:hover > [data-attribute="image-overlay"] {
      opacity: 1;
    }
  }
`

const HomepageTag = styled.span`
  background-color: #EA5527;
  position: absolute;
  left: 0;
  top: 40%;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  i {
    color: #f5f5f5;
  }
`

const Pages = ({ siteId, site, history, templates }) => {
  const [deletePage] = useMutation(DELETE_PAGE);
  const { loading, error, data } = useQuery(PAGES_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      siteId: parseInt(siteId)
    }
  });

  if (loading) return <Loader />;
  if (error) return <div>Error</div>;

  let pages = []
  if(data && data.pages && data.pages.length) {
    const homepage = data.pages.find(item => !item.slug)
    if(homepage) {
      pages = [homepage, ...data.pages.filter(item => item.slug)] 
    } else {
      pages = data.pages
    }
  }

  const handleClick = () => {
    history.push("/pages/new");
  }

  const handleDeletePage = pageId => () => {
    const response = window.confirm("Are you sure you want to delete this page?");
    if(response) {
      mutation({ 
        name: "deletePage",
        func: deletePage,
        args: { id: pageId },
        update: (store) => {
          try {
            const data = store.readQuery({ query: PAGES_QUERY, variables: { siteId } });
            const newPages = {}
            newPages.pages = data.pages.filter(page => page.id !== pageId);
            store.writeQuery({ query: PAGES_QUERY, data: newPages, variables: { siteId } });
          } catch (error) {
            console.log("An error occured while updating the cache", error)
          }
        },
        callbackError: (error) => console.log({ error })
      });
    }
  }

  return (
    <Container>
      <TopBar>
        <h2>{site.name}</h2>
        <Button color="primary" onClick={handleClick}>New Page</Button>
      </TopBar>
      <PagesList>
        {!!pages.length ? pages.map((p, i) => {
          const pageId = idToHash(p.id);
          const template = templates.find(template => template.id === p.templateId);
          const templateScreenshot = template ? template.imageUrl : "";
          return (
            <Page key={i}>
              {!p.slug && <HomepageTag><i className="fas fa-home"></i></HomepageTag>}
              <div data-attribute="page-image">
                <img src={templateScreenshot}/>
                <div data-attribute="image-overlay">
                  <div data-attribute="image-overlay-btn">
                    <Dropdown>
                      <button data-attribute="dropbtn">Actions</button>
                      <div data-attribute="dropdown-content">
                        <a href={`/pages/${pageId}`}>Edit</a>
                        <a onClick={handleDeletePage(p.id)}>Delete</a>
                      </div>
                    </Dropdown>
                  </div>
                </div>
              </div> 
              <Link to={`/pages/${pageId}`}>
                {p.name}
              </Link>
              {p.slug}
            </Page>
          );
        }) : (
          <h2>No pages exist</h2>
        )}
      </PagesList>
    </Container>
  );
};

export default Pages;
