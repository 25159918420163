import React from 'react';

import { useHistory } from "react-router-dom";

import { useMutation } from "@apollo/client";

import { CREATE_TEMPLATE } from "graphql/templates";

import { mutation, yupValidations } from "utils";

import { Formik } from "formik";

import { object } from "yup";

import { Form } from "styles";

const Schema = object().shape({
  name: yupValidations.name
});

const NewTemplate = () => {
  const [ createTemplate ] = useMutation(CREATE_TEMPLATE);
  let history = useHistory();

  return (
    <div>
      <Formik
        initialValues={{ name: "" }}
        validationSchema={Schema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          mutation({
            name: "createTemplate",
            func: createTemplate,
            args: { name: values.name },
            callbackSuccess: ((data) => {
              const { createTemplate: { template: { id } }} = data;
              history.push(`/admin/templates/${id}`);
            }),
            callbackError: (() => console.log("handle error"))
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <label dataattribute="form-label" htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
            />
            {touched.name && errors.name && <span dataattribute="form-error-msg">{errors.name}</span>}
            <button type="submit" disabled={isSubmitting || !isValid}>
              Create Template
            </button>
            {errors.error && <span dataattribute="form-error-msg">{errors.error}</span>}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default NewTemplate;