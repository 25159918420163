
const mutation = ({ name, func, args, update=null, callbackSuccess=null, callbackError=null }) => {
  return new Promise(async () => {
    try {
      const response = await func({ 
        variables: args,  
        ...(update && {update})
      });
      const { data, errors } = response;
      const { [name]: { success, error } } = data;
      if(!success) {
        throw(error);
      };
      callbackSuccess && callbackSuccess(data);
    } catch (error) {
      console.log(`Error occured in ${name}: "${error}"`);
      // * cannot display server errors to the user
      const errorMsg = error && typeof error !== "object" ? error : "Something went wrong";
      callbackError && callbackError(errorMsg);
    }
  })
}

export {
  mutation
}