import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { useQuery } from "@apollo/client";

import { mutation, hashToId } from "utils";

import Designer from "./Designer";

import Loader from "../common/Loader";

import { PAGE_QUERY } from "graphql/page";

const Page = (props) => {
  let params = useParams();
  const { step } = params;
  const pageId = hashToId(params.pageId);

  const { loading, error, data, refetch } = useQuery(PAGE_QUERY, {
    variables: { id: parseInt(pageId) },
  });

  if (loading) return <Loader />;
  if (error) return <div>Error</div>;

  const page = data.page;

  return (
    <Designer
      {...props}
      data={page}
      step={step}
      refetch={refetch}
    />
  );
};

export default Page;
