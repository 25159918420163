import React, { Fragment, useState, useEffect } from 'react';

import { Switch, Route, useHistory, Redirect } from "react-router-dom";

import CreatePage from "../components/CreatePageFlow/CreatePage";
import EditPage from "../components/CreatePageFlow/EditPage";
import LoggedIn from "./LoggedIn";

import { CREATE_USER_AND_SITE } from "graphql/user";
import { TEMPLATES_QUERY } from "graphql/templates";

import { mutation, idToHash } from "utils";

import { useQuery, useMutation } from "@apollo/client";

import TopNav from "../components/TopNavExternal";

import { Container } from "reactstrap";

import Loader from "../components/common/Loader";

import styled from "styled-components";

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 100px;
  background-color: #FFF;

  .logo {
    flex: 1;

    img {
      height: 50px;
    }
  }

  .action {
    flex: 1;
    text-align: right;

    a {
      color: #000;
      font-weight: 600;
      text-decoration: none;
    }
  }
`

const CreatePageFlow = () => {
  let history = useHistory();
  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState({
    slug: "",
    email: "",
    password: "",
    template: {
      body: null,
      css: null,
      fonts: {},
      html: null,
      templateId: null,
      json: {},
      name: ""
    }
  })
  const isLoggedIn = localStorage.getItem("mypage:token");

  const [createUserAndSite] = useMutation(CREATE_USER_AND_SITE);

  useEffect(() => {
    if (submitted) {
      mutation({
        name: "createUserAndSite",
        func: createUserAndSite,
        args: {
          email: data.email,
          password: data.password,
          siteName: data.slug,
          siteSlug: data.slug,
          pageName: "homepage",
          templateId: data.template.id,
          html: data.template.html,
          body: data.template.body,
          json: data.template.json,
          css: data.template.css,
          fonts: data.template.fonts,
        },
        callbackSuccess: (data) => {
          if (data && data.createUserAndSite && data.createUserAndSite.success) {
            localStorage.setItem(
              "mypage:token",
              data.createUserAndSite.token
            );
            localStorage.setItem(
              "mypage:siteId",
              data.createUserAndSite.site.id
            );
            const pageId = idToHash(data.createUserAndSite.page.id);
            history.push(`/pages/${pageId}`);
          }
        },
        callbackError: (error) => console.log(error)
      });
    }
  }, [submitted])

  const { loading, error, data: templatesData } = useQuery(TEMPLATES_QUERY, {
    fetchPolicy: "network-only",
  });

  if (loading) return <Loader />;
  if (error) return <div>Error</div>;

  const templates = templatesData.templates;

  const childProps = {
    history,
    templates
  };

  return (
    <Fragment>
      {!isLoggedIn && (
        <TopNav />
      )}
      
      <Switch>
        {!isLoggedIn && (
          <Container>
            <Route exact path="/">
              <CreatePage data={data} setData={setData} setSubmitted={setSubmitted} {...childProps} />
            </Route>

            <Route exact path="/create">
              <EditPage data={data} setData={setData} setSubmitted={setSubmitted} {...childProps} />
            </Route>
          </Container>
        )}

        <LoggedIn {...childProps} />
      </Switch>
    </Fragment>
  );
}

export default CreatePageFlow;