import React, { useState } from "react";

import { Formik } from "formik";

import { object, string } from "yup";

import { idToHash, mutation, yupValidations, generateSlug } from "utils";

import { useMutation, useLazyQuery } from "@apollo/client";

import { CREATE_SITE } from "graphql/site";

import { CHECK_SLUG_UNIQUENESS } from "graphql/site";

const NewSite = ({ history }) => {
  const [slugName, setSlugName] = useState();

  const [createSite] = useMutation(CREATE_SITE);

  const [
    checkSlugUniqueness,
    { data },
  ] = useLazyQuery(CHECK_SLUG_UNIQUENESS, {
    variables: { slugName }
  });

  const Schema = object().shape({
    name: yupValidations.name,
    slug: string().required("Required")
      .test(
        "is-unique",
        "Slug is not unique",
        async function() {
          if(slugName) {
            await checkSlugUniqueness();
            if(data && !data.isSiteSlugNameAvailable) return false;
            return true;
          }
          return true;
        },
      ),
  });

  return (
    <div>
      New Site
      <Formik
        initialValues={{ name: "", slug: "" }}
        validationSchema={Schema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          values.slug = slugName;
          mutation({ 
            name: "createSite",
            func: createSite,
            args: values,
            callbackSuccess: (data) => {
              if (data && data.createSite && data.createSite.success) {
                const site = data.createSite.site;
                const siteId = idToHash(site.id);
                history.push(`/sites/${siteId}`);
              }
            },
            callbackError: (error) => setErrors({ error })
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <label dataattribute="form-label" htmlFor="name">Site Name</label>
            <input
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
            />
            {errors.name && touched.name && errors.name}
            <label dataattribute="form-label" htmlFor="slug">Slug</label>
            <input
              type="text"
              name="slug"
              onChange={event => {
                let { value } = event.target;
                setFieldValue("slug", value);
                value = generateSlug(value);
                setSlugName(value);
              }}
              onBlur={handleBlur}
              value={values.slug}
            />
            {errors.slug && touched.slug && errors.slug}
            {errors.error && <span dataattribute="form-error-msg">{errors.error}</span>}

            <button type="submit" disabled={isSubmitting || !isValid}>
              Submit
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default NewSite;
