import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { ApolloProvider } from "@apollo/client";
import client from "graphql/client";

import SignUp from "./components/Auth/SignUp";
import SignIn from "./components/Auth/SignIn";
import SignOut from "./components/Auth/SignOut";
import CreatePageFlow from "./containers/CreatePageFlow";

import { Container } from "reactstrap";

const App = () => {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Switch>
          <Route path="/login">
            <SignIn />
          </Route>
          <Route path="/signup">
            <SignUp />
          </Route>
          <Route path="/logout">
            <SignOut />
          </Route>
          <Route path="/">
            <CreatePageFlow />
          </Route>
        </Switch>
      </Router>
    </ApolloProvider>
  );
};

export default App;
