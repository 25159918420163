import React from "react";

import styled from "styled-components";

const Container = styled.div`
  text-align: center;
  padding: 30px;
  color: #999;
`;

const Loader = ({}) => {
  return (
    <Container className="fa-2x">
      <i className="fas fa-spinner fa-spin"></i>
    </Container>
  );
};

export default Loader;
