import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache
} from "@apollo/client";

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI
});

const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("mypage:token") || "";

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : ""
    }
  });

  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink)
});

export default client;
