import React from "react";

import { useHistory, Link } from "react-router-dom";

import { useMutation } from "@apollo/client";

import { Formik } from "formik";

import { object } from "yup";

import { CREATE_USER } from "graphql/user";

import { mutation, yupValidations } from "utils";

import { AuthContainer } from "styles";
import { Button, Form, FormGroup, Label, Input, FormText, FormFeedback } from 'reactstrap';

import logo from "../../images/logo.png";

import styled from "styled-components";

const Footer = styled.div`
  margin-top: 30px;
`

const Schema = object().shape({
  email: yupValidations.email,
  password: yupValidations.password,
});

const Register = () => {
  const [createUser] = useMutation(CREATE_USER);
  let history = useHistory();

  return (
    <AuthContainer>
      <div className="content">
        <div className="logo">
          <Link to="/">
            <img src={logo} />
          </Link>
        </div>

        <h1>Sign Up</h1>

        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={Schema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            mutation({
              name: "createUser",
              func: createUser,
              args: values,
              callbackSuccess: (data) => {
                if (data && data.createUser && data.createUser.success) {
                  localStorage.setItem(
                    "mypage:token",
                    data.createUser.token
                  );
                  history.push("/sites");
                }
              },
              callbackError: (error) => setErrors({ error })
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid
            /* and other goodies */
          }) => (
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    name="email"
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    invalid={touched.email && errors.email}
                  />
                  {touched.email && errors.email && <FormFeedback>{errors.email}</FormFeedback>}
                </FormGroup>

                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    name="password"
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    invalid={touched.password && errors.password}
                  />
                  {touched.password && errors.password && <FormFeedback>{errors.password}</FormFeedback>}
                </FormGroup>

                <Button color="primary" disabled={isSubmitting || !isValid}>
                  Sign Up
                      </Button>

                {errors.error && <FormText>{errors.error}</FormText>}

                <Footer>
                  <div>Already have an account? <Link to="/login">Sign In</Link></div>
                </Footer>
              </Form>
            )}
        </Formik>
      </div>

      <div className="background"></div>
    </AuthContainer>
  );
};

export default Register;
