import React from 'react';

import { Formik } from "formik";

import { useMutation } from "@apollo/client";

import styled from "styled-components";

import { object } from "yup";

import { UPDATE_USER } from "graphql/user";

import { Form } from "styles";

import { mutation, yupValidations } from "utils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
`

const Title = styled.h2`

`

const Logout = styled.div`

`

const Schema = object().shape({
  email: yupValidations.email,
  password: yupValidations.password,
  passwordConfirm: yupValidations.passwordConfirm,
});


const Account = ({ history, currentUser }) => {
  const [ updateUser ] = useMutation(UPDATE_USER);
  const { email } = currentUser;

  const handleLogout = () => {
    history.push("/logout");
  }

  return (
    <Container>
      <Title>Change account details here</Title>
      <Formik
        initialValues={{ email, password: "", passwordConfirm: "" }}
        validationSchema={Schema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          mutation({ 
            name: "updateUser",
            func: updateUser,
            args: values,
            callbackError: (error) => setErrors({ error })
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          status
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <label dataattribute="form-label" htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            {touched.email && errors.email && <span dataattribute="form-error-msg">{errors.email}</span>}
            <label dataattribute="form-label" htmlFor="password">Current Password</label>
            <input
              type="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
            />
            {touched.password && errors.password && <span dataattribute="form-error-msg">{errors.password}</span>}
            <label dataattribute="form-label" htmlFor="passwordConfirm">New Password</label>
            <input
              type="password"
              name="passwordConfirm"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.passwordConfirm}
            />
            {touched.passwordConfirm && errors.passwordConfirm && <span dataattribute="form-error-msg">{errors.passwordConfirm}</span>}
            <button type="submit" disabled={isSubmitting || !isValid}>
              Submit
            </button>
            {errors.error && <span dataattribute="form-error-msg">{errors.error}</span>}
            {status && <span dataattribute="form-success-msg">Updated Successfully!</span>}
          </Form>
        )}
      </Formik>
      <Logout>
        <button onClick={handleLogout}>Logout</button>
      </Logout>
    </Container>
  );
}
export default Account;
