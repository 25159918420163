const generateSlug = slug => {
  slug = slug
    .trim()
    .replace(/ /g, "-");

  return slug;
}

export {
  generateSlug
}