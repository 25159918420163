import React from 'react';

import TemplateCards from "../common/TemplateCards";

import styled from "styled-components";

const Container = styled.div`
  flex: 1;
`

const ChooseTemplate = ({ templates, nextStep, data, setData }) => {

  const chooseTemplate = templateId => {
    const template = templates.find(t => t.id === templateId);
    setData({...data, template});
    nextStep(2);
  }

  return (
    <Container>
      <TemplateCards templates={templates} chooseTemplate={chooseTemplate} />
    </Container>
  );
}

export default ChooseTemplate;