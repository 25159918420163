import React, { useEffect } from 'react';

import { useFormik } from "formik";

import { object } from "yup";


import { Row, Col, Button, Form, FormGroup, Label, Input, FormText, FormFeedback } from 'reactstrap';

import { yupValidations } from "utils";

import styled from "styled-components";

const Container = styled.div`
  h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 35px;
  }
`;

const SaveUser = ({ data, setData, setSubmitted }) => {

  const validationSchema = object().shape({
    email: yupValidations.email,
    password: yupValidations.password,
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema,
    onSubmit: () => {
      if (data.slug) {
        setSubmitted(true);
        formik.setSubmitting(false);
      } else {
        alert("Enter slug!");
      }
    },
  });

  useEffect(() => {
    setData({
      ...data,
      email: formik.values.email,
      password: formik.values.password,
    })
  }, [formik.values])

  return (
    <Row>
      <Col xs="6">
        <Container>
          <h1>Sign Up</h1>
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                name="email"
                type="email"
                onChange={formik.handleChange}
                onFocus={event => {
                  event.target.removeAttribute("readOnly");
                }}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                invalid={formik.errors.email}
              />
              {formik.errors.email && <FormFeedback>{formik.errors.email}</FormFeedback>}
            </FormGroup>

            <FormGroup>
              <Label for="password">Password</Label>
              <Input
                name="password"
                type="password"
                onChange={formik.handleChange}
                onFocus={event => {
                  event.target.removeAttribute("readOnly");
                }}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                invalid={formik.errors.password}
              />
              {formik.errors.password && <FormFeedback>{formik.errors.password}</FormFeedback>}
            </FormGroup>

            <Button color="primary" type="submit" disabled={formik.isSubmitting || !formik.isValid}>
              Submit
            </Button>
            {formik.errors.error && <FormFeedback>{formik.errors.error}</FormFeedback>}
          </Form>
        </Container>
      </Col>
    </Row>
  );
}

export default SaveUser;