import React, { useState } from "react";

import styled from "styled-components";

import Modal from 'react-modal';

const Container = styled.div`
  h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 35px;
  }
`;

const TemplateCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 270px));
  grid-gap: 75px;

  [data-attribute="template"] {
    padding: 5px;
  }

  .template-card {
    position: relative;
    border: 1px solid;
    width: 280px;
    border: 1px solid #80808069;
    border-radius: 5px;

    img {
      width: 100%;
    }

    .image-overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #000000b3;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: .3s ease;
    }

    .image-overlay-btn {
      position: absolute;
      bottom: 0px;
      right: 0px;
      margin: 0px 25px 20px 0px;
    }

    .preview-btn {
      border: 1px solid #fff;
      background: transparent;
      border-radius: 5px;
      padding: 5px 10px;
      color: #fff;
      transition: .2s ease;

      &:hover {
        cursor: pointer;
        background: #fff;
        color: #000;
      }
    }

    &:hover > .image-overlay {
      opacity: 1;
      cursor: pointer;
    }
  }
`;

const PreviewImage = styled.img`
  height: 100%;
`

const TemplateCards = ({ templates, chooseTemplate }) => {
  const [ modalIsOpen, setIsOpen ] = useState(false);
  const [ activeTemplate, setActiveTemplate ] = useState({});

  // Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
  Modal.setAppElement('#root');

  const customStyles = {
      overlay: {
        zIndex: 120,
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
      },
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0px',
      },
  };

  const handleChooseTemplate = id => () => {
    chooseTemplate(id);
  }

  const showTemplatePreview = template => event => {
    event.stopPropagation();
    setActiveTemplate(template);
    setIsOpen(true);
  }

  return (
    <Container>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
      >
        <PreviewImage src={activeTemplate.imageUrl || ""} />
      </Modal>

      <h1>Choose a Template</h1>

      <TemplateCardsContainer>
        {templates.map(t => (
          <div key={`template-card-${t.id}`} onClick={handleChooseTemplate(t.id)} className="template-card">
            <img src={t.imageUrl}/>
            <div className="image-overlay">
              <div className="image-overlay-btn">
                <button onClick={showTemplatePreview(t)} className="preview-btn">Preview</button>
              </div>
            </div>
          </div>
        ))}
      </TemplateCardsContainer>
    </Container>
  );
};

export default TemplateCards;
