import React, {
  useState,
  forwardRef,
  useImperativeHandle
} from "react";

import { useLazyQuery } from "@apollo/client";

import { CHECK_SLUG_UNIQUENESS, CHECK_HOMEPAGE_AVAILABILITY } from "graphql/page";

import debounce from "lodash/debounce";

import styled from "styled-components";

import { useFormik } from "formik";

import { string, object } from "yup";

import { Form, IframeContainer } from "styles";

import { yupValidations } from "utils";

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const Info = forwardRef(({ siteId, data, handleSave }, ref) => {
  const [ nextStep, setNextStep ] = useState();

  const validationSchema = object().shape({
    name: yupValidations.name,
    page: string()
      .test(
        "doesHomepageExist",
        "Homepage already exists!",
        async function(value) {
          if(value === "home") {
            return new Promise(async (resolve, reject) => {
              await doesHomePageExist();
              if (homepageAvailabilityData && homepageAvailabilityData.doesHomePageExist) {
                reject(this.createError({ path: this.path, message: "Homepage already exists!" }));
              }
              resolve(true);
            })
          } 
          return true;
      }
    ),
    slug: string()
      .when("page", {
        is: "custom",
        then: string().required("Slug is required").test(
          "isUnique",
          "Slug is not unique",
            async function(value) {
              if(this.options.parent.page === "custom") {
                return new Promise(async (resolve, reject) => {
                  await checkSlugUniqueness();
                  if (uniqueSlugData && !uniqueSlugData.isPageSlugNameAvailable) {
                    reject(this.createError({ path: this.path, message: "Slug is not unique" }));
                  }
                  resolve(true);
              })
            }
            return true;
          }
        )
      })
  });

  const formik = useFormik({ 
    initialValues: {
      name: data.name,
      page: data.slug === null ? "home" : "custom",
      slug: data.slug || "" 
    },
    validationSchema,
    onSubmit: (values) => {
      handleSave({
        updates: { 
          name: values.name,
          slug: values.page === "custom" ? values.slug : null 
        },
        nextStep: nextStep === null ? "publish" : nextStep,
      });
    },
    validateOnChange: true,
    validateOnBlur: true,
  });

  const [
    checkSlugUniqueness,
    { data: uniqueSlugData },
  ] = useLazyQuery(CHECK_SLUG_UNIQUENESS, {
    variables: {
      id: data.id, slugName: formik.values.slug, siteId
    },
  });

  const [
    doesHomePageExist,
    { data: homepageAvailabilityData },
  ] = useLazyQuery(CHECK_HOMEPAGE_AVAILABILITY, {
    variables: {
      id: data.id
    },
  });

  useImperativeHandle(ref, () => ({
    save(nextStep) {
      if(!!Object.keys(formik.errors).length) {
        alert("Fix errors!")
      }
      setNextStep(nextStep);
      formik.handleSubmit();
    }
  }));

  return (
    <Container>
      <div>
        <Form onSubmit={formik.handleSubmit}>
          <label dataattribute="form-label" htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name && <span dataattribute="form-error-msg">{formik.errors.name}</span>}

          <label htmlFor="page">Your Pages</label>
            <select
            id="page"
            name="page"
            onChange={event => {
              formik.setFieldValue("slug", "");
              formik.handleChange(event);
            }}
            onBlur={formik.handleBlur}
            defaultValue={formik.values.page}
          >
            <option value="home">Home Page</option>
            <option value="custom">Custom Page</option>
          </select>
          {formik.errors.page && <span dataattribute="form-error-msg">{formik.errors.page}</span>}

          {(formik.values.page === "custom") && (
            <div>
              <label htmlFor="slug">Enter New Slug...</label>
              <input
                name="slug"
                onKeyUp={event => {
                  formik.setFieldError("slug", false);
                }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.slug}
              />
            </div>
          )}
          {formik.errors.slug && <span dataattribute="form-error-msg">{formik.errors.slug}</span>}
          {formik.errors.error && <span dataattribute="form-error-msg">{formik.errors.error}</span>}
        </Form>
      </div>

      <IframeContainer>
        <iframe
          srcDoc={data.html}
        />
      </IframeContainer>
    </Container>
  );
});

export default Info;
