import React, { useEffect, useState } from "react";

import { Switch, Route } from "react-router-dom";

import TopNav from "../components/TopNav";
import Sites from "../components/Sites";
import Pages from "../components/Pages";

import { useLazyQuery } from "@apollo/client";

import { SITE_QUERY } from "graphql/site";

import Loader from "../components/common/Loader";

import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const SiteContainer = (props) => {
  return (
    <Container>
      <Switch>
        <Route path="/sites">
          <Sites {...props} />
        </Route>
        <Route path="/pages">
          <Pages {...props} />
        </Route>
      </Switch>
    </Container>
  );
};

const NoSiteContainer = (props) => {
  return (
    <Container>
      <Switch>
        <Route path="/sites">
          <Sites {...props} />
        </Route>
      </Switch>
    </Container>
  );
};

const Site = ({ currentUser, history, templates }) => {
  const [activeSiteId, setActiveSiteId] = useState(null);
  const [activeSite, setActiveSite] = useState(null);

  const [
    loadSite,
    { called, loading, error, data, refetch: refetchSite },
  ] = useLazyQuery(SITE_QUERY, {
    variables: { id: parseInt(activeSiteId) },
  });

  useEffect(() => {
    if (activeSiteId) {
      loadSite();
    } else if (localStorage.getItem("mypage:siteId")) {
      setActiveSiteId(localStorage.getItem("mypage:siteId"));
    } else {
      history.push("/sites");
    }
  }, [activeSiteId]);

  useEffect(() => {
    if (data && data.site) {
      setActiveSite(data.site);
    } else if (data && !data.site) {
      localStorage.removeItem("mypage:siteId");
      history.push("/");
    }
  }, [data]);

  if (called && loading) return <Loader />;
  if (called && error) return <div>Error</div>;

  const isSiteAvailable = activeSiteId && activeSite;

  const childProps = {
    currentUser,
    setActiveSiteId,
    history,
    site: activeSite,
    siteId: parseInt(activeSiteId),
    templates
  };

  return (
    <>
      <TopNav {...childProps} />
      {isSiteAvailable ? (
        <SiteContainer {...childProps} />
      ) : (
          <NoSiteContainer {...childProps} />
        )}
    </>
  )
};

export default Site;
