import * as Yup from "yup";

const passwordRegex = {
  lowercaseRegex: /(?=.*[a-z])/,
  uppercaseRegex: /(?=.*[A-Z])/,
  numericRegex: /(?=.*[0-9])/
}

const yupValidations = {
  email: Yup.string()
    .lowercase()
    .email("Must be a valid email!")
    .required("Required!"),
  password: Yup.string()
    .matches(passwordRegex.lowercaseRegex, "one lowercase required!")
    .matches(passwordRegex.uppercaseRegex, "one uppercase required!")
    .matches(passwordRegex.numericRegex, "one number required!")
    .min(8, "Minimum 8 characters required!")
    .max(20, "Maximum 20 characters required!")
    .required("Required!"),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password")], "Password must be the same!")
    .required("Required!"),
  name: Yup.string()
    .required("Name cannot be empty."),
  slug: Yup.string()
    .required("Required!"),
  page: Yup.string()
    .required("Required!")
}

export {
  yupValidations
}
