import React from "react";
import { Redirect } from "react-router-dom";

import client from "graphql/client";

const Signout = () => {
  localStorage.removeItem("mypage:token");
  client.resetStore();

  return (
    <Redirect
      to={{
        pathname: "/login"
      }}
    />
  );
};

export default Signout;
