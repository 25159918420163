import Hashids from "hashids";

const hashids = new Hashids("mYp4g3", 5);

const idToHash = id => {
  return hashids.encode(id);
};

const hashToId = hashId => {
  return hashids.decode(hashId)[0];
};

export { idToHash, hashToId };
