import React, { useState } from 'react';

import ChooseTemplate from "./ChooseTemplate";
import SaveUser from "./SaveUser";

import styled from "styled-components";

const Container = styled.div`
  height: 100%;
  max-width: 1160px;
  margin: 0 auto;
  font-family: 'Inter', sans-serif;
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
`;

const EditPage = ({ data, setData, templates, ...props }) => {
  const [ step, setStep ] = useState(1);

  const childProps = {
    data,
    setData,
    nextStep: step => {
      setStep(step);  
    },
    ...props
  }
  
  const renderStep = () => {
    if(step === 1) return <ChooseTemplate templates={templates} {...childProps} />
    if(step === 2) return <SaveUser {...childProps} />
    return <p>Choose a step.</p>
  }

  return (
    <Container>
      {renderStep()}
    </Container>
  );
}

export default EditPage;