import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";

import Loader from "../common/Loader";

import debounce from "lodash/debounce";

import styled from "styled-components";

const Container = styled.div`
  flex: 1;

  #editor-container {
    height: 100%;
  }
`;

const Unlayer = forwardRef(({ step, data, handleSave, allowAutosave=true }, ref) => {
  const [scriptLoaded, setScriptLoaded] = useState(
    typeof unlayer != "undefined"
  );

  useEffect(() => {
    if (typeof unlayer == "undefined") {
      const script = document.createElement("script");
      script.src = "https://editor.unlayer.com/embed.js";
      document.body.appendChild(script);

      script.onload = () => {
        setScriptLoaded(true);
      };
    } else {
      setScriptLoaded(true);

      window.unlayer.init({
        id: "editor-container",
        projectId: 1,
        displayMode: "web",
      });

      if (data.json && Object.keys(data.json).length > 0) {
        window.unlayer.loadDesign(data.json);
      }

      if(allowAutosave) {
        // Auto save on changes
        window.unlayer.addEventListener(
          "design:updated",
          debounce((data) => {
            window.unlayer.exportHtml((data) => {
              handleSave({
                updates: {
                  json: data.design,
                  html: data.html,
                  body: data.chunks.body,
                  css: data.chunks.css,
                  fonts: data.chunks.fonts,
                },
                nextStep: null
              });
            });
          }, 500)
        );
      }
    }
  }, [scriptLoaded]);

  useImperativeHandle(ref, () => ({
    save(nextStep) {
      window.unlayer.exportHtml((data) => {
        handleSave({
          updates: {
            json: data.design,
            html: data.html,
            body: data.chunks.body,
            css: data.chunks.css,
            fonts: data.chunks.fonts,
          },
          nextStep: nextStep === null ? "info" : nextStep
        });
      });
    },
  }));

  return (
    <Container>
      {!scriptLoaded && <Loader />}
      {scriptLoaded && <div id="editor-container"></div>}
    </Container>
  );
})

export default Unlayer;