import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";

import { IframeContainer } from "styles";

import { CHECK_SLUG_UNIQUENESS, CHECK_HOMEPAGE_AVAILABILITY } from "graphql/page";

import { useQuery } from "@apollo/client";

import styled from "styled-components";

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const Publish = forwardRef(({ site, data, handleSave }, ref) => {
  const [ errors, setErrors ] = useState({});
  const { data: uniqueSlugData } = useQuery(CHECK_SLUG_UNIQUENESS, {
    variables: {
      id: data.id, slugName: data.slug, siteId: site.id
    },
  });
  const { data: homepageAvailabilityData } = useQuery(CHECK_HOMEPAGE_AVAILABILITY, {
    variables: {
      id: data.id
    },
  });

  useEffect(() => {
    if(!data.name) {
      setErrors({...errors, name: "Page name cannot be empty!"})
    }
    if(uniqueSlugData && !uniqueSlugData.isPageSlugNameAvailable) {
      setErrors({...errors, customPage: "Please use a unique page slug."})
    }
    if(!data.slug && homepageAvailabilityData && homepageAvailabilityData.doesHomePageExist) {
      setErrors({...errors, homePage: "Home page already exists! You cannot create a new one."})
    }
  }, [uniqueSlugData, homepageAvailabilityData])

  useImperativeHandle(ref, () => ({
    save(nextStep) {
      handleSave({
        updates: {},
        nextStep: nextStep === null ? "publish" : nextStep,
        pagePublished: nextStep === null
      });
    }
  }));

  const renderleftPanel = () => {
    if(!Object.keys(errors).length) {
      return (
        <div>
          Your page is published!
          <a href={`my.page/${data.slug}${data.slug ? `/${data.slug} ` : ""}`}>
            {`my.page/${site.slug}${data.slug ? `/${data.slug} ` : ""}`}
          </a>
        </div>
      )
    } else {
      return (
        <div>
          <h2>Errors!</h2>
          {Object.keys(errors).map((error, index) => (
            <p key={`error-${index}`}>{errors[error]}</p>
          ))}
        </div>
      )
    }
  }

  return (
    <Container>
      <div>
        {renderleftPanel()}
      </div>

      <IframeContainer>
        <iframe
          srcDoc={data.html}
        />
      </IframeContainer>
    </Container>
  );
});

export default Publish;
