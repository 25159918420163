import { gql } from "@apollo/client";

/*
  MUTATIONS
*/
const CREATE_SITE = gql`
  mutation CreateSite($name: String!, $slug: String!) {
    createSite(input: { name: $name, slug: $slug }) {
      success
      error
      site {
        id
        name
      }
    }
  }
`;

/*
  QUERIES
*/

const SITE_QUERY = gql`
  query getSite($id: Int!) {
    site(id: $id) {
      id
      name
      slug
    }
  }
`;


const SITES_QUERY = gql`
  query getSites {
    sites {
      id
      name
    }
  }
`;

const CHECK_SLUG_UNIQUENESS = gql`
  query isSiteSlugNameAvailable($slugName: String!) {
    isSiteSlugNameAvailable(slugName: $slugName)
  }
`;


export {
  CREATE_SITE,
  SITE_QUERY,
  SITES_QUERY,
  CHECK_SLUG_UNIQUENESS
}