import { gql } from "@apollo/client";

/*
  MUTATIONS
*/

const CREATE_TEMPLATE = gql`
  mutation CreateTemplate(
    $name: String!
    $html: String
    $body: String
    $json: JSON
    $css: String
    $fonts: JSON
  ) {
    createTemplate(input: { 
      name: $name
      html: $html
      body: $body
      json: $json
      css: $css
      fonts: $fonts
     }) {
      success
      error
      template {
        id
        name
      }
    }
  }
`;

const UPDATE_TEMPLATE = gql`
  mutation UpdateTemplate(
    $id: Int!
    $name: String
    $html: String
    $body: String
    $json: JSON
    $css: String
    $fonts: JSON
  ) {
    updateTemplate(input: { 
      id: $id
      name: $name
      html: $html
      body: $body
      json: $json
      css: $css
      fonts: $fonts
     }) {
      success
      error
      template {
        id
        name
      }
    }
  }
`;

const DELETE_TEMPLATE = gql`
  mutation DeleteTemplate($id: Int!) {
    deleteTemplate(input: { id: $id }) {
      success
      error
    }
  }
`;

/*
  QUERIES
*/

const TEMPLATES_QUERY = gql`
  query getTemplates {
    templates {
      id
      name
      html
      json
      body
      css
      fonts
      imageUrl
    }
  }
`;

const TEMPLATE_QUERY = gql`
  query getTemplate($id: Int!) {
    template(id: $id) {
      id
      name
      html
      json
      body
      css
      fonts
      imageUrl
    }
  }
`;

export {
  CREATE_TEMPLATE,
  UPDATE_TEMPLATE,
  DELETE_TEMPLATE,
  TEMPLATES_QUERY,
  TEMPLATE_QUERY
}