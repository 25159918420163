import React, { Fragment, useState, useEffect } from 'react';

import logo from "images/logo.png";

import { Container, Row, Col } from "reactstrap";

import styled from "styled-components";

const HeaderContainer = styled.div`
  height: 100px;
  background-color: #FFF;
  padding: 25px 0px;

  .logo {
    img {
      height: 50px;
    }
  }

  .action {
    height: 50px;
    line-height: 50px;
    text-align: right;

    a {
      color: #000;
      font-weight: 600;
      text-decoration: none;
    }
  }
`

const Header = () => {
  return (
    <HeaderContainer>
      <Container>
        <Row noGutters>
          <Col>
            <div className="logo">
              <a href="/">
                <img src={logo} alt="MyPage Logo" />
              </a>
            </div>
          </Col>

          <Col>
            <div className="action">
              <a href="/login">Sign In</a>
            </div>
          </Col>
        </Row>
      </Container>
    </HeaderContainer>
  );
}

export default Header;