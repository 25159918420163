import React, { useState } from "react";

import { Redirect, Switch, Route, useRouteMatch } from "react-router-dom";

import { useQuery } from "@apollo/client";

import { TEMPLATES_QUERY } from "graphql/templates";

import styled from "styled-components";

import TemplatesList from "../components/Pages/Designer/Templates/admin/TemplatesList";
import EditTemplate from "../components/Pages/Designer/Templates/admin/EditTemplate";
import NewTemplate from "../components/Pages/Designer/Templates/admin/NewTemplate";
import Dashboard from "../components/Dashboard";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Admin = ({ currentUser, history }) => {
  let { path } = useRouteMatch();
  const [activeSiteId, setActiveSiteId] = useState(localStorage.getItem("mypage:siteId"));
  const [activeSite, setActiveSite] = useState(null);

  const { loading, error, data } = useQuery(TEMPLATES_QUERY, {
    fetchPolicy: "network-only",
  });

  if (loading) return <div>Loading</div>;
  if (error) return <div>Error</div>;


  const childProps = {
    currentUser,
    setActiveSiteId,
    history,
    site: activeSite,
    siteId: parseInt(activeSiteId),
  };

  const templates = data.templates;

  if(!currentUser.roles.includes("ADMIN")) {
    return (
      <Redirect
        to={{
          pathname: "/sites",
        }}
      />
    );
  }

  return (
    <Container>
      <Switch>
        <Route exact path={path}>
          <Dashboard {...childProps} />
        </Route>
        <Route path={`${path}/templates/new`}>
          <NewTemplate {...childProps} />
        </Route>
        <Route path={`${path}/templates/:id`}>
          <EditTemplate/>
        </Route>
        <Route path={`${path}/templates`}>
          <TemplatesList templates={templates} {...childProps} />
        </Route>
      </Switch>
    </Container>
  );
};

export default Admin;
