import { gql } from "@apollo/client";

/*
  MUTATIONS
*/

const CREATE_PAGE = gql`
  mutation CreatePage(
    $siteId: Int!
    $templateId: Int!
    $name: String!
    $html: String
    $body: String
    $css: String
    $json: JSON
    $fonts: JSON
  ) {
    createPage(
      input: {
        siteId: $siteId
        templateId: $templateId
        name: $name
        html: $html
        body: $body
        css: $css
        json: $json
        fonts: $fonts
      }
    ) {
      success
      error
      page {
        id
        name
        html
        json
        body
        css
        fonts
      }
    }
  }
`;

const UPDATE_PAGE = gql`
  mutation UpdatePage(
    $id: Int!
    $templateId: Int
    $name: String
    $html: String
    $body: String
    $css: String
    $json: JSON
    $fonts: JSON
    $slug: String
  ) {
    updatePage(
      input: {
        id: $id
        templateId: $templateId
        name: $name
        html: $html
        body: $body
        css: $css
        json: $json
        fonts: $fonts
        slug: $slug
      }
    ) {
      success
      error
      page {
        id
        name
        html
        json
        body
        css
        fonts
        slug
      }
    }
  }
`;

const DELETE_PAGE = gql`
  mutation DeletePage($id: Int!) {
    deletePage(input: { id: $id }) {
      success
      error
    }
  }
`;

/*
  QUERIES
*/

const CHECK_SLUG_UNIQUENESS = gql`
  query isPageSlugNameAvailable($id: Int!, $siteId: Int!, $slugName: String!) {
    isPageSlugNameAvailable(id: $id, siteId: $siteId, slugName: $slugName)
  }
`;

const CHECK_HOMEPAGE_AVAILABILITY = gql`
  query doesHomePageExist($id: Int!) {
    doesHomePageExist(id: $id)
  }
`;

const PAGE_QUERY = gql`
  query getPage($id: Int!) {
    page(id: $id) {
      id
      name
      html
      json
      body
      css
      fonts
      slug
    }
  }
`;

const PAGES_QUERY = gql`
  query getPages($siteId: Int!) {
    pages(siteId: $siteId) {
      id
      templateId
      siteId
      name
      slug
      html
      json
      body
      css
      fonts
    }
  }
`;

export {
  UPDATE_PAGE,
  DELETE_PAGE,
  CREATE_PAGE,
  CHECK_SLUG_UNIQUENESS,
  CHECK_HOMEPAGE_AVAILABILITY,
  PAGE_QUERY,
  PAGES_QUERY,
}