import React from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";

import Dashboard from "../components/Dashboard";
import Account from "../components/Account";
import Site from "./Site";
import Admin from "./Admin";

import TopNav from "../components/TopNav";
import Loader from "../components/common/Loader";

import { useQuery } from "@apollo/client";

import { ME_QUERY } from "graphql/user";

const LoggedIn = (props) => {
  let history = useHistory();

  const { loading, error, data } = useQuery(ME_QUERY);

  if (loading) return <Loader />;

  if (error || !data || !data.me) {
    localStorage.removeItem("mypage:token");

    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }

  const childProps = {
    currentUser: data.me,
    history,
    ...props
  };

  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/">
          <Dashboard {...childProps} />
        </Route>

        <Route path="/account">
          <>
            <TopNav {...childProps} />
            <Account {...childProps} />
          </>
        </Route>

        <Route path="/admin">
          <>
            <TopNav {...childProps} />
            <Admin {...childProps} />
          </>
        </Route>

        <Site {...childProps} />
      </Switch>
    </React.Fragment>
  );
};

export default LoggedIn;
