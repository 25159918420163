import React from "react";
import { Link } from "react-router-dom";

import { idToHash } from "utils";

import { useQuery } from "@apollo/client";

import { SITES_QUERY } from "graphql/site";

import Loader from "../common/Loader";

import { Button } from "reactstrap";
import { TopBar } from "styles";

import styled from "styled-components";

const Container = styled.div`
  padding: 40px 20px;
  background-color: #FFF;
`

const Sites = ({ history }) => {
  const { loading, error, data } = useQuery(SITES_QUERY, {
    fetchPolicy: "network-only"
  });

  if (loading) return <Loader />;
  if (error) return <div>Error</div>;

  const handleClick = () => {
    history.push("/sites/new");
  }

  return (
    <Container>
      <TopBar>
        <h2>Sites</h2>
        <Button color="primary" onClick={handleClick}>New Site</Button>
      </TopBar>
      <div>
        {data.sites.map((s, i) => {
          const siteId = idToHash(s.id);
          return (
            <div key={i}>
              <Link to={`/sites/${siteId}`}>{s.name}</Link>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export default Sites;
