import React, { useState, useRef } from "react";

import { useParams } from 'react-router-dom'

import Unlayer from "../../../../common/Unlayer";

import { useQuery, useMutation } from "@apollo/client";

import { TEMPLATE_QUERY } from "graphql/templates";

import { UPDATE_TEMPLATE } from "graphql/templates";

import { mutation } from "utils";

import { Formik } from "formik";

import { Form } from "styles";

import styled from "styled-components";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const EditTemplate = () => {
  let { id } = useParams()
  const [ name, setName ] = useState('');
  const childRef = useRef(null);

  const [ updateTemplate ] = useMutation(UPDATE_TEMPLATE);
  const { loading, error, data } = useQuery(TEMPLATE_QUERY, {
    variables: { id: parseInt(id) },
    fetchPolicy: "network-only",
  });

  if (loading) return <div>Loading</div>;
  if (error) return <div>Error</div>;

  const template = data.template;

  const saveTemplate = async ({ updates }) => {
    mutation({
      name: "updateTemplate",
      func: updateTemplate,
      args: { id: parseInt(id), name, ...updates },
    });
  }

  return (
    <Container>
      <Formik
        initialValues={{ name: data.template && data.template.name ? data.template.name : "" }}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          setName(values.name);
          childRef.current.save();
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <label dataattribute="form-label" htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
            />
            {touched.name && errors.name && <span dataattribute="form-error-msg">{errors.name}</span>}
            <button type="submit" disabled={isSubmitting || !isValid}>
              Save Changes
            </button>
            {errors.error && <span dataattribute="form-error-msg">{errors.error}</span>}
          </Form>
        )}
      </Formik>

      <Unlayer data={template} handleSave={saveTemplate} allowAutosave={false} ref={childRef} />
    </Container>
  );
}

export default EditTemplate;