import React, { forwardRef } from "react";

import Unlayer from "../../../common/Unlayer";

const Design = forwardRef((props, ref) => {

  return <Unlayer {...props} ref={ref} />
});

export default Design;
