import React, { useState } from "react";

import { idToHash, mutation, yupValidations } from "utils";

import TemplateCards from "../common/TemplateCards";

import { useMutation } from "@apollo/client";

import { CREATE_PAGE } from "graphql/page";

import { Formik } from "formik";

import { object } from "yup";

import Modal from 'react-modal';

import styled from "styled-components";

import { Form } from "styles";

const Container = styled.div`
  padding: 40px 100px;
`

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  [data-attribute="site-name"] {
    font-size: 24px;
    text-transform: capitalize;
  }
`

const Schema = object().shape({
  name: yupValidations.name
});

const NewPage = ({ siteId, site, history, templates }) => {
  const [templateId, setTemplateId] = useState();
  const [modalIsOpen,setIsOpen] = useState(false);

  // Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
  Modal.setAppElement('#root');

  const [createPage, {}] = useMutation(CREATE_PAGE);

  const chooseTemplate = templateId => {
    setTemplateId(templateId);
    setIsOpen(true);
  }

  return (
    <Container>
      <TopBar>
        <div data-attribute="site-name">{site.name}</div>
      </TopBar>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="Enter template name"
      >
        <Formik
          initialValues={{ name: "" }}
          validationSchema={Schema}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            const template = templates.find((t) => t.id == templateId);
            mutation({ 
              name: "createPage",
              func: createPage,
              args: {
                siteId,
                templateId: template.id,
                html: template.html,
                body: template.body,
                css: template.css,
                fonts: template.fonts,
                json: template.json,
                name: values.name,
              },
              callbackSuccess: (data) => {
                if (data && data.createPage && data.createPage.success) {
                  const pageId = idToHash(data.createPage.page.id);
                  history.push(`/pages/${pageId}`);
                }
              }
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit}>
              <label dataattribute="form-label" htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
              {touched.name && errors.name && <span dataattribute="form-error-msg">{errors.name}</span>}
              <button type="submit" disabled={isSubmitting || !isValid}>
                Submit
              </button>
              {errors.error && <span dataattribute="form-error-msg">{errors.error}</span>}
            </Form>
          )}
        </Formik>
      </Modal>
      <TemplateCards templates={templates} siteId={siteId} chooseTemplate={chooseTemplate} />
    </Container>
  );
};

export default NewPage;
