import React from "react";

import classnames from "classnames";

const Steps = ({ step, pageId, onSave, saving }) => {
  return (
    <div className="wizard-steps" style={{ flex: 1, maxHeight: 80 }}>
      <ul className="steps">
        <li className={classnames({ step: true, active: !step })}>
          <a onClick={onSave("")} className="text" to={`/pages/${pageId}`}>
            Design
          </a>
        </li>
        <li className={classnames({ step: true, active: step == "info" })}>
          <a onClick={onSave("info")} className="text">
            Info
          </a>
        </li>
        <li className={classnames({ step: true, active: step == "publish" })}>
          <a onClick={onSave("publish")} className="text">
            Publish
          </a>
        </li>
      </ul>
      <a
        className={classnames({ save: true, saving: saving })}
        onClick={onSave()}
      >
        {saving ? "Saving" : "Save"}
      </a>
    </div>
  );
};

export default Steps;
