import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Pages from "./Pages";
import Page from "./Page";
import NewPage from "./NewPage";

import { Container } from "reactstrap";

const PagesContainer = (props) => {
  let { path } = useRouteMatch();

  const childProps = {
    ...props,
  };

  return (
    <Switch>
      <Route exact path={`${path}/new`}>
        <Container>
          <NewPage {...childProps} />
        </Container>
      </Route>
      <Route exact path={`${path}/:pageId/:step?`}>
        <Page {...childProps} />
      </Route>
      <Route exact path={path}>
        <Container>
          <Pages {...childProps} />
        </Container>
      </Route>
    </Switch>
  );
};

export default PagesContainer;
