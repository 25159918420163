import React, { useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";

import { hashToId } from "utils";

const Site = ({ site, setActiveSiteId }) => {
  let params = useParams();
  const siteId = hashToId(params.siteId);

  useEffect(() => {
    localStorage.setItem("mypage:siteId", siteId);
    setActiveSiteId(siteId);
  }, [siteId]);

  if (!site) return null;

  return (
    <Redirect
      to={{
        pathname: "/pages"
      }}
    />
  );
};

export default Site;
