import { gql } from "@apollo/client";

/*
  MUTATIONS
*/

const SIGN_IN_USER = gql`
  mutation SignInUser($email: String!, $password: String!) {
    signInUser(input: { email: $email, password: $password }) {
      success
      error
      token
    }
  }
`;

const CREATE_USER = gql`
  mutation CreateUser($email: String!, $password: String!) {
    createUser(input: { email: $email, password: $password }) {
      success
      error
      token
      user {
        id
        email
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($email: String, $currentPassword: String, $newPassword: String, $name: String) {
    updateUser(input: { email: $email, currentPassword: $currentPassword, newPassword: $newPassword, name: $name }) {
      success
      error
      token
      user {
        id
        email
      }
    }
  }
`;

const CREATE_USER_AND_SITE = gql`
mutation CreateUserAndSite(
  $email: String!,
  $password: String!,
  $siteName: String!,
  $siteSlug: String!,
  $templateId: Int!,
  $pageName: String!,
  $html: String,
  $body: String,
  $json: JSON,
  $css: String,
  $fonts: JSON,
) {
  createUserAndSite(input: {
    email: $email,
    password: $password,
    siteName: $siteName,
    siteSlug: $siteSlug,
    templateId: $templateId,
    pageName: $pageName,
    html: $html,
    body: $body,
    json: $json,
    css: $css,
    fonts: $fonts,
  }) {
    success
    error
    token
    user {
      id
      email
    }
    site {
      id
    }
    page {
      id
    }
  }
}
`;

/*
  QUERIES
*/

const ME_QUERY = gql`
  {
    me {
      id
      email
      roles
    }
  }
`;

export {
  SIGN_IN_USER,
  CREATE_USER,
  UPDATE_USER,
  CREATE_USER_AND_SITE,
  ME_QUERY
}
