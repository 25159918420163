import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import classnames from "classnames";

import styled from "styled-components";

const AccountThumb = styled.div`
  background-color: #ccc;
  color: #555;
  flex: 1;
  text-align: center;
`;

const Container = styled.div`
  background-color: rgba(37, 47, 63, 1);
  flex: 1;
  max-height: 75px;
`;

const Nav = ({ currentUser, errors, site }) => {
  const location = useLocation();

  return (
    <Container className="nav">
      <div
        className={classnames({
          nav__wrapper: true,
          container: true
        })}
      >
        <div className="nav__items__left">
          <NavLink
            className="nav__brand"
            activeClassName="active"
            to="/sites"
          >
            <img className="nav__logo" src="/logo.png" />
          </NavLink>

          {(!site || location.pathname === "/sites") && (
            <NavLink className="nav__item" activeClassName="active" to="/sites">
              Sites
            </NavLink>
          )}

          {(!!site && location.pathname !== "/sites") && (
            <>
              <NavLink
                className="nav__item"
                activeClassName="active"
                to="/sites"
              >
                {site.name}
              </NavLink>
              <NavLink
                className="nav__item"
                activeClassName="active"
                to="/pages"
              >
                Pages
              </NavLink>
            </>
          )}
        </div>

        <div className="nav__items__right">
          <NavLink className="navbrand__user" to="/account">
            <AccountThumb>
              <i className="fas fa-user" />
            </AccountThumb>
          </NavLink>
          {currentUser.email}
        </div>
      </div>
    </Container>
  );
};

export default Nav;
