import React from 'react';

import { useHistory } from "react-router-dom";

import { useMutation } from "@apollo/client";

import { UPDATE_TEMPLATE, DELETE_TEMPLATE, TEMPLATES_QUERY } from "graphql/templates";

import { mutation } from "utils";

import { Formik } from "formik";

import { Form } from "styles";

import styled from "styled-components";

const Template = styled.div`
  padding: 5px;

  &:hover {
    cursor: pointer;
  }
`;

const TemplatesList = ({ templates }) => {
  const [ updateTemplate ] = useMutation(UPDATE_TEMPLATE);
  const [ deleteTemplate ] = useMutation(DELETE_TEMPLATE);
  let history = useHistory();

  const createTemplate = () => {
    history.push("/admin/templates/new");
  }

  const editTemplate = ({ id: templateID }) => {
    history.push(`/admin/templates/${templateID}`);
  }

  const handleUpdateTemplate = ({id: templateID, name: templateName}) => (values, { resetForm, setSubmitting } ) => {
    mutation({
      name: "updateTemplate",
      func: updateTemplate,
      args: { id: templateID, name: values.name },
      callbackSuccess: (() => {
        setSubmitting(false);
        resetForm();
      }),
      callbackError: (() => console.log("handle error"))
    });
  }

  const handleDeleteTemplate = ({ id: templateID }) => {
    mutation({
      name: "deleteTemplate",
      func: deleteTemplate,
      args: { id: templateID },
      update: (store) => {
        try {
          const data = store.readQuery({ query: TEMPLATES_QUERY });
          const newTemplates = {};
          newTemplates.templates = data.templates.filter(template => template.id !== templateID);
          store.writeQuery({ query: TEMPLATES_QUERY, data: newTemplates });
        } catch (error) {
          console.log("An error occured while updating the cache", error)
        }
      },
      callbackError: (() => console.log("handle error"))
    });
  }

  return (
    <div>
      <button onClick={createTemplate}>Create new template</button>
      {templates.map(t => (
        <span key={`template${t.id}`} >
          <Template onClick={() => editTemplate(t)}>
            {t.name}
          </Template>
          <Formik
            initialValues={{ name: "" }}
            onSubmit={handleUpdateTemplate(t)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit}>
                <label dataattribute="form-label" htmlFor="name">Name</label>
                <input
                  id={`template-${t.id}`}
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
                {touched.name && errors.name && <span dataattribute="form-error-msg">{errors.name}</span>}
                <button type="submit" disabled={isSubmitting || !isValid}>
                  Update Name
                </button>
                {errors.error && <span dataattribute="form-error-msg">{errors.error}</span>}
              </Form>
            )}
          </Formik>
          <button onClick={() => handleDeleteTemplate(t)}>Delete</button>
          <hr/>
        </span>
      ))}
    </div>
  );
}

export default TemplatesList;